// import React from "react";
// import { UIStateProvider } from "./src/context/UIStateContext";

import "@fontsource/noto-sans-kr";
import "@fontsource/black-han-sans";
import "./src/styles/global.scss";

// export const wrapRootElement = ({ element }) => (
//   <UIStateProvider>{element}</UIStateProvider>
// );
